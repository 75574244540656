import React from 'react';

import { LogoOverauto } from 'app/components/LogoOverauto';

import { ImageLogoAutoDNA, imageLogoCebiaPng } from 'app/utils/images';

export const comparatorConfig = vin => ({
  overautoFree: {
    thumb: <LogoOverauto badgeText='Zdarma' />,
    features: [
      'Technické údaje vozidla',
      'Základní historie tachometru',
      'Skutečný rok výroby',
      'Historie inzerce',
      'Aktuální tržní cena',
      'Přehled podobných vozidel na trhu',
      'Zůstatková cena',
    ],
    price: 'zdarma',
    footerLinkUrl: vin ? `/vin/${vin}` : '',
    footerLinkText: vin ? 'Zpět na report' : '',
  },
  overautoPremium: {
    thumb: <LogoOverauto badgeText='Premium' bgColor='#46DA8C' />,
    featuresTitle: 'Navíc oproti našemu reportu ZDARMA:',
    features: [
      'Ověření zástavních práv leasingových a úvěrových společností',
      'Ověření odcizení v ČR a SR',
      'Ověření odcizení v celé EU',
      'Export do formátu PDF',
      'Ověření v registru vozidel ČR',
    ],
    price: '149',
    currency: 'Kč',
    selected: true,
    footerButtonText: vin ? 'Zakoupit PREMIUM report' : '',
    footerButtonUrl: vin ? `/vin/${vin}/platba` : '', // TODO: change
    footerButtonColor: 'highlight',
    footerButtonName: 'btn-buy-premium',
  },
  autoDna: {
    thumb: <ImageLogoAutoDNA />,
    featuresTitle: 'Navíc oproti našemu reportu ZDARMA:',
    features: ['Ověření odcizení v ČR a SR', 'Ověření odcizení v celé EU'],
    price: '200',
    priceCents: ',50',
    currency: 'Kč',
    footerLinkUrl: 'http://www.overauto.cz',
    footerLinkText: 'Zobrazit ukázkový report',
    footerButtonText: vin ? 'Přejít na autoDNA' : '',
    footerButtonUrl: vin ? 'https://www.overauto.cz' : '', // TODO: change
    footerButtonColor: '#3E5959',
    footerButtonName: 'btn-go-to-autodna',
  },
  cebia: {
    thumb: <img src={imageLogoCebiaPng} alt='cebia logo' />,
    featuresTitle: 'Navíc oproti našemu reportu ZDARMA:',
    features: [
      'Pokročilá historie tachometru',
      'Skutečný rok výroby',
      'Historie inzerce',
      'Fotografie vozidla',
      'Ověření zástavních práv leasingových a úvěrových společností',
      'Ověření odcizení v ČR a SR',
    ],
    price: '399',
    priceOriginal: '499 Kč',
    currency: 'Kč',
    footerLinkUrl: 'http://www.overauto.cz',
    footerLinkText: 'Zobrazit ukázkový report',
    footerButtonText: vin ? 'Přejít na Cebii' : '',
    footerButtonUrl: vin ? 'https://www.overauto.cz' : '', // TODO: change
    footerButtonColor: '#3E5959',
    footerButtonName: 'btn-go-to-cebia',
  },
});
