import * as React from 'react';
import * as S from './SectionHeader.styles';
import Typography from 'app/components/Typography';
import { Row } from 'app/components/Row';
import Heading from '../Heading';
import { colors } from 'styles/shared.styles';

interface ISectionHeaderProps {
  title: string;
  bgColor?: string;
  subheaderText?: string;
  subheaderValue?: string | number;
  subheaderSuffixText?: string;
  subheaderRightText?: string;
  allowBottomBorder?: boolean;
  rightComponent?: React.ReactNode;
}

const SectionHeader: React.SFC<ISectionHeaderProps> = ({
  title,
  subheaderText,
  subheaderSuffixText,
  subheaderValue,
  subheaderRightText,
  rightComponent,
  bgColor,
  allowBottomBorder = true,
}) => (
  <S.Header bgColor={bgColor} allowBottomBorder={allowBottomBorder}>
    <S.HeaderLeft>
      <S.HeadingContainer>
        <Heading level={2} weight='regular' size='medium'>
          {title}
        </Heading>
      </S.HeadingContainer>
      {subheaderText && (
        <S.SubheaderContainer>
          <S.SubHeaderTitleContainer>
            <Typography text={subheaderText} size='base' color={colors.textGrey} />
          </S.SubHeaderTitleContainer>
        </S.SubheaderContainer>
      )}
      {(typeof subheaderValue !== 'undefined' || subheaderSuffixText) && (
        <Row alignItems='center' mt={['10px', 0]}>
          {typeof subheaderValue !== 'undefined' && (
            <S.SubHeaderValueContainer>
              <Typography text={subheaderValue.toString()} size='base' weight='medium' />
            </S.SubHeaderValueContainer>
          )}
          {subheaderSuffixText && (
            <S.SubheaderSuffixTextContainer>
              <Typography text={subheaderSuffixText} size='base' color={colors.textGrey} />
            </S.SubheaderSuffixTextContainer>
          )}
        </Row>
      )}
    </S.HeaderLeft>
    {(subheaderRightText || rightComponent) && (
      <S.HeaderRight>
        {subheaderRightText && <Typography size='small' text={subheaderRightText} />}
        {rightComponent}
      </S.HeaderRight>
    )}
  </S.Header>
);

export default SectionHeader;
