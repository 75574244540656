import React from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';

import { Row } from 'app/components/Row';
import Button from 'app/components/Button';
import { redirectRoute } from 'app/routes';
import { fontFamily } from 'styles/shared.styles';
import { useVerifyVinRoute } from 'app/hooks';
import { mediaQuery } from 'styles/shared.styles';

export const ButtonPayReport = () => {
  const { isPremiumVersion } = useVerifyVinRoute();
  const { vin } = useParams<{ vin: string }>();
  if (isPremiumVersion) return null;
  const URL = `/vin/${vin}/platba`;

  return (
    <Row
      alignItems='center'
      bottom='20px'
      justifyContent='center'
      position='fixed'
      width={1}
      zIndex='1000'
    >
      <StyledButton
        borderRadius='50px'
        data-cy='btn-pay-report'
        font={fontFamily.medium}
        height='50px'
        onClick={() => redirectRoute(URL)}
        padding={['15px', '0 40px']}
      >
        Odemknout celý report
      </StyledButton>
    </Row>
  );
};

const StyledButton = styled(Button)`
  font-size: 1.062rem;
  box-shadow: 0px 12px 24px rgba(23, 128, 250, 0.43);
  padding: 15px;
  ${mediaQuery.small} {
    padding: 0 40px;
  }
`;
