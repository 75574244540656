import React, { useEffect } from 'react';
import styled from 'styled-components/macro';

import { LogoOverauto } from 'app/components/LogoOverauto';
import Heading from 'app/components/Heading';
import { Column, TColumnProps } from 'app/components/Column';
import Typography from 'app/components/Typography';
import Button from 'app/components/Button';
import { VinTextBadge } from 'app/components/VinTextBadge';
import { loadAdByVin } from 'app/actions/ads';
import { useDispatch } from 'react-redux';
import { useAuthenticated } from 'app/hooks';
import { useTypedSelector } from 'app/store';
import { iconSuccessV1 } from 'app/utils/icons';
import { makePayment } from 'app/utils/payments';
import { TFnWithArgs, TPaymentCategory } from 'app/types';

interface IMakePaymentProps extends TColumnProps {
  btnText?: string;
  gwUrl: string;
  paymentInfo?: string;
  paymentType: TPaymentCategory;
  setLoading?: TFnWithArgs;
  vin: string;
}

export const MakePayment: React.FC<IMakePaymentProps> = ({
  vin,
  btnText = 'Zakoupit report',
  paymentInfo,
  gwUrl,
  setLoading,
  paymentType,
  ...containerProps
}) => {
  const dispatch = useDispatch();
  const { accessToken } = useAuthenticated();
  const adByVin = useTypedSelector(({ ads }) => ads.adByVin);

  const handleBtnClick = () => makePayment(vin, gwUrl, accessToken, paymentType, setLoading);

  useEffect(() => {
    const adNotLoaded = !adByVin && vin && accessToken;
    if (adNotLoaded) {
      const apiLoadAdByVin = vin => dispatch(loadAdByVin(vin, accessToken));
      apiLoadAdByVin(vin);
    }
  }, [vin, dispatch, accessToken, adByVin]);

  return (
    <Column width={[1, '473px']} mb='60px' {...containerProps}>
      <Top vin={vin} />
      <Bottom vin={vin} btnText={btnText} paymentInfo={paymentInfo} onClick={handleBtnClick} />
    </Column>
  );
};

const Top = ({ vin }) => (
  <Column alignItems='center' bg='bgPrimary' borderRadius='9px 9px 0px 0px' py='45px' px='20px'>
    <Logo />
    <Header vin={vin} />
  </Column>
);

const Bottom = ({ vin, paymentInfo, onClick, btnText }) => (
  <Column
    alignItems='center'
    justifyContent='center'
    bg='white'
    borderRadius='0px 0px 9px 9px'
    p={['40px 20px 25px', '60px 40px 25px']}
  >
    <PaymentDescription />
    <ButtonPayment onClick={onClick} btnText={btnText} />
    {paymentInfo && <Typography text={paymentInfo} size='small' textAlign='center' />}
  </Column>
);

const ButtonPayment = ({ onClick, btnText }) => (
  <Column my='35px'>
    <Button onClick={onClick} borderRadius='18px' padding='0 30px' data-cy='make-payment-btn'>
      {btnText}
    </Button>
  </Column>
);

const Logo = () => <LogoOverauto badgeText='Premium' bgColor='#46DA8C' theme='light' />;

const Header = ({ vin }) => {
  const adTitle = useTypedSelector(({ ads }) => ads.adByVin?.adTitle);
  return (
    <Column alignItems='center'>
      <Typography text='Ověřovaný vůz' color='white' margin='27px 0 3px 0' />
      <Heading level={2} weight='bold' fontSize={['1.6rem', '1.875rem']} color='white'>
        {adTitle || vin}
      </Heading>
      {!!adTitle && <VinTextBadge vin={vin} theme='light' />}
    </Column>
  );
};

const PaymentDescription = () => (
  <ul>
    <ListItem>
      <Typography
        text='Ověření registrů odcizených vozidel<br />ve 14 zemích EU.'
        weight='medium'
        lineHeight='19px'
      />
    </ListItem>
    <ListItem>
      <Typography
        text='Prověření databáze 23 leasingových<br />a úvěrových společností.'
        weight='medium'
        lineHeight='19px'
      />
    </ListItem>
    <ListItem>
      <Typography text='Ověření záznamů v registru vozidel ČR.' weight='medium' lineHeight='19px' />
    </ListItem>
  </ul>
);

const ListItem = styled.li`
  position: relative;
  margin-bottom: 20px;
  padding-left: 32px;
  line-height: 28px;

  &:before {
    content: ' ';
    background-size: cover;
    background-image: url(${iconSuccessV1});
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    top: 3px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;
