import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import AdHistory from './components/AdHistory';
import Ad from 'app/models/Ad';
import { showLoginDialog } from 'app/actions/app';

export interface IHistoryDb {
  title: string;
  notes: string;
  img: string;
  price: number;
  km: number;
  vin: string;
  equipmentCount: number;
  lastRun: Date;
  description?: string;
}

export interface IHistoryTableData {
  oldValue: string;
  newValue: string;
  changedAt: string;
  fieldName: {
    cz: string;
    en: string;
  };
}

export interface IHistoryChartData {
  priceHistory: Array<{
    date: string;
    value: number;
  }>;
  kmHistory: Array<{
    date: string;
    value: number;
  }>;
}

export interface IHistory {
  initialData: IHistoryDb | null;
  tableData: IHistoryTableData[];
  tableDataLength: number;
  chartData: IHistoryChartData;
}

export interface IKmHistory {
  data: IHistory;
  ad: Ad;
  kmManipulation?: number;
}

export interface IStateProps {
  history: IHistory;
  ad?: Ad;
  isAuthorized?: boolean;
}

interface IStateDispatchProps {
  showLoginDialog: () => void;
}

type TProps = IStateProps & IStateDispatchProps & RouteComponentProps;

class AdHistoryContainer extends React.Component<TProps> {
  public static defaultProps: any = {
    history: {
      initialData: null,
      tableData: [],
      chartData: {
        priceHistory: [],
        kmHistory: [],
      },
      tableDataLength: 0,
    },
  };

  public render() {
    const { ad, isAuthorized, history } = this.props;

    if (!ad) {
      return null;
    }

    return (
      <AdHistory
        history={history}
        isAuthorized={isAuthorized}
        showLoginDialog={this.props.showLoginDialog}
        chartPriceSubheaderText='Stáří inzerátu'
        chartPriceSubheaderValue={Ad.getAdAgeDaysTitle((ad && ad.adAge) || 0)}
        chartKmSubheaderText='Stáří inzerátu'
        chartKmSubheaderValue={Ad.getAdAgeDaysTitle((ad && ad.adAge) || 0)}
        tableSubheaderText={history.tableDataLength}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    history: state.ads.history,
    ad: state.ads.adDetails.ad,
    isAuthorized: !!state.auth.accessToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showLoginDialog: () => dispatch(showLoginDialog()),
  };
};

export default withRouter(
  connect<any, any, any>(mapStateToProps, mapDispatchToProps)(AdHistoryContainer)
);
