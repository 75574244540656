import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import Notification from 'app/components/Notification';
import Button from 'app/components/Button';

import { redirectRoute } from 'app/routes';
import { fontFamily, mediaQuery } from 'styles/shared.styles';
import { useVerifyVinRoute } from 'app/hooks';

const textMap = {
  invalidVin: 'Vložený VIN kód je neúplný. Níže nabízíme podobné vozy z inzerce.',
  freeVersion:
    'Prohlížíte zdarma dostupnou verzi ověření, pro kompletní prověření tohoto vozidla je potřeba report odemknout.',
};

export const FreeReportNotification: React.FC = () => {
  const { isValidVin } = useVerifyVinRoute();
  const text = isValidVin ? textMap.freeVersion : textMap.invalidVin;
  return (
    <StyledNotification
      id='verify-vin-free-report'
      text={text}
      type='default'
      size='medium'
      textFontFamily={fontFamily.regular}
      bgColor='primary'
      centered={false}
      sticky={true}
      rightComponent={isValidVin ? <ButtonReportUnlock /> : null}
      lineHeight={[1.5, 2]}
      textAlign={['center', 'left']}
    />
  );
};

const ButtonReportUnlock = () => {
  const { vin } = useParams<{ vin: string }>();
  const URL = `/vin/${vin}/platba`;
  const redirectToReports = () => redirectRoute(URL);
  return (
    <Button
      bgColor='white'
      textColor='#4A5561'
      borderRadius='18px'
      font={fontFamily.regular}
      onClick={redirectToReports}
      data-cy='free-report-notification-btn'
    >
      Odemknout report
    </Button>
  );
};

const StyledNotification = styled(Notification)`
  display: none;

  ${mediaQuery.medium} {
    display: block;
  }
`;
