import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { get } from 'lodash-es';
import { useLocation } from 'react-router-dom';

import Home from 'app/pages/Home';
import About from 'app/pages/About';
import Contact from 'app/pages/Contact';
import TermsOfService from 'app/pages/TermsOfService';
import PrivacyPolicy from 'app/pages/PrivacyPolicy';
import ResetPassword from 'app/pages/ResetPassword';
import { PagePayVinReport } from 'app/pages/PagePayVinReport';
import { PageUserPayments } from 'app/pages/PageUserPayments';
import { Version } from 'app/pages/Version';
import ScrollToTop from 'app/components/ScrollToTop';
import Feedback from 'app/components/Feedback';
import GoogleTagManager from 'app/components/GoogleTagManager';
import NotificationBar from 'app/components/NotificationBar';
import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import EstimateCar from 'app/components/EstimateCar';
import { VerifyVin } from 'app/components/VerifyVin';
import { NotFound } from 'app/components/NotFound';
import { VerifyVinMobileApp } from 'app/components/VerifyVin';
import MyCars from 'app/pages/MyCars';
import { PagePayRegistry } from 'app/pages/PagePayRegistry';

import { routes } from './routes';
import { PublicRoute } from './PublicRoute';

export const AppRouter = () => {
  const location = useLocation();
  const gtmIsEnabled = Boolean(parseInt(process.env.REACT_APP_GTM_ENABLED as string, 10));
  const getVin = renderProps => get(renderProps, 'match.params.vin', '');

  const footerEnabled = () => {
    const { pathname } = location;
    const footerEnabled = !['/kontakt', '/contact'].includes(pathname);
    return footerEnabled;
  };

  return (
    <Switch>
      <Route
        path={routes.MOBILE_VERIFY_VIN}
        exact={true}
        render={props => (
          <>
            {gtmIsEnabled && <GoogleTagManager gtmId={process.env.REACT_APP_GTM_ID} />}
            <VerifyVinMobileApp vin={getVin(props)} />
          </>
        )}
      />
      <Route
        render={props => {
          return (
            <ScrollToTop>
              <div className='app__main--container'>
                {gtmIsEnabled && <GoogleTagManager gtmId={process.env.REACT_APP_GTM_ID} />}
                <NotificationBar />
                <Header />
                <div className='app__main--content'>
                  <Switch>
                    <Route path={routes.ABOUT} component={About} />
                    <Route path={[routes.CONTACT, routes.KONTAKT]} component={Contact} />
                    <Route path={routes.TERMS_OF_SERVICE} component={TermsOfService} />
                    <Route path={routes.PRIVACY_POLICY} component={PrivacyPolicy} />
                    <Route path={routes.RESET_PASSWORD} component={ResetPassword} />
                    <Route path={routes.FEEDBACK} component={Feedback} />
                    <Route path={routes.PRICE_ADVISOR} component={EstimateCar} />
                    <Route
                      path={[routes.MEMBERSHIP, routes.USER_PAYMENTS]}
                      component={PageUserPayments}
                    />
                    <Route path={routes.MY_CARS} component={MyCars} />
                    <Route path={routes.VERSION} component={Version} />

                    {/* verify vin*/}
                    <Route
                      path={[routes.VERIFY_VIN, routes.VERIFY_VIN_PREMIUM]}
                      exact
                      component={VerifyVin}
                    />
                    <PublicRoute path={routes.VERIFY_VIN_PAY_REPORT} component={PagePayVinReport} />
                    <PublicRoute
                      path={routes.VERIFY_VIN_REGISTERS_PAYMENT}
                      exact
                      component={PagePayRegistry}
                    />

                    <Route path={routes.HOME} component={Home} />
                    <Route component={NotFound} />
                  </Switch>
                </div>
                {footerEnabled() && <Footer />}
              </div>
            </ScrollToTop>
          );
        }}
      />
    </Switch>
  );
};
