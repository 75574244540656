import React from 'react';
import styled from 'styled-components/macro';

import Typography from 'app/components/Typography';
import Button from 'app/components/Button';
import { Column } from 'app/components/Column';
import { Row } from 'app/components/Row';
import Link from 'app/components/Link';
import { IconSeparator } from 'app/components/molecules/IconSeparator';
import { IconSearch, iconListBullet } from 'app/utils/icons';
import { redirectTo } from 'app/utils/url.utils';

interface IComparatorCardProps {
  currency?: string;
  features: string[];
  featuresTitle?: string;
  footerButtonColor?: string;
  footerButtonText?: string;
  footerButtonUrl?: string;
  footerLinkText?: string;
  footerLinkUrl?: string;
  footerButtonName?: string;
  price: string;
  priceCents?: string;
  priceOriginal?: string;
  selected?: boolean;
  thumb: React.ReactNode;
  vin?: string;
}

export const ComparatorCard: React.FC<IComparatorCardProps> = ({
  currency,
  features,
  featuresTitle,
  footerButtonColor,
  footerButtonText,
  footerButtonUrl,
  footerLinkText,
  footerLinkUrl,
  footerButtonName,
  price,
  priceCents,
  priceOriginal,
  selected,
  thumb,
  vin,
}) => (
  <Column
    background='white'
    boxShadow='0px 2px 21px rgba(0, 0, 0, 0.133031)'
    borderRadius='6px'
    width='330px'
    padding={selected ? '50px 30px' : '30px'}
    position='relative'
    justifyContent='space-between'
    zIndex={selected ? 1 : 0}
    margin={selected ? '-20px' : 0}
  >
    <Column>
      <Column
        width={1}
        alignItems='center'
        minHeight={'120px'}
        justifyContent='center'
        mt={selected ? '20px' : 0}
      >
        {thumb}
      </Column>
      <IconSeparator icon={<IconSearch />} mx='20px' />
      <Column pt={featuresTitle ? '29px' : '60px'}>
        <Typography text={featuresTitle} weight='bold' fontSize='14px' mb='10px' />
        <List>
          {features?.map(feature => (
            <ListItem key={feature}>{feature}</ListItem>
          ))}
        </List>
      </Column>
    </Column>
    <Footer mt='45px' mb={0} alignItems='center'>
      <Price
        priceOriginal={priceOriginal}
        price={price}
        priceCents={priceCents}
        currency={currency}
      />
      {footerButtonUrl && (
        <Column mb={3} width={1}>
          <Button
            bgColor={footerButtonColor}
            onClick={() => redirectTo(footerButtonUrl, true)}
            data-cy={`comparator-card-btn comparator-card-btn-${footerButtonName}`}
          >
            {footerButtonText}
          </Button>
        </Column>
      )}
      <FooterLink url={footerLinkUrl} urlText={footerLinkText} />
    </Footer>
  </Column>
);

/**
 * Components
 */

const List = styled.ul``;

const ListItem = styled.li`
  line-height: 1.3;
  letter-spacing: -0.192857px;
  font-size: 0.937rem; // 15px
  background: url(${iconListBullet}) no-repeat 1px 7px transparent;
  margin: 0 0 15px 0;
  padding: 0px 0px 0px 15px;
  vertical-align: middle;
`;

const Footer = styled(Column)``;

const Price = ({ priceOriginal, price, priceCents, currency }) => (
  <Row mb='10px' alignItems='flex-end'>
    {priceOriginal && (
      <Typography
        text={priceOriginal}
        color='#CCCCCC'
        fontSize='26px'
        letterSpacing='-2.5px'
        lineHeight='38px'
        textDecoration='line-through'
        mr='8px'
      />
    )}
    <Typography
      text={price}
      color='primary'
      fontSize='50px'
      letterSpacing='-2.5px'
      lineHeight='50px'
      weight='bold'
    />
    {priceCents && (
      <Typography
        text={priceCents}
        color='primary'
        fontSize='26px'
        letterSpacing='-1.3px'
        lineHeight='33px'
        weight='bold'
      />
    )}
    {currency && (
      <Typography
        text={currency}
        color='primary'
        fontSize='26px'
        letterSpacing='-1.3px'
        lineHeight='34px'
        ml='10px'
      />
    )}
  </Row>
);

const FooterLink = ({ url, urlText }) =>
  url ? (
    <Row>
      <Link
        external={url?.includes('http')}
        to={url}
        letterSpacing='-0.192857px'
        textDecoration='underline'
        fontSize='15px'
        lineHeight='17px'
        color='#4A5561'
        colorHover='#4A5561'
      >
        {urlText}
      </Link>
    </Row>
  ) : null;
