import * as moment from 'moment';
import { isUndefined, get } from 'lodash-es';
import * as CONSTANTS_API from 'app/constants/api';
import * as CONSTANTS_APP from 'app/constants/app';
import Benchmark from 'app/models/Benchmark';
import AvgMonthValues from 'app/models/AvgMonthValues';
import { ComparedObject } from 'app/models/ComparedObject';
import Ad from 'app/models/Ad';
import AdFilter from 'app/models/AdFilter';
import {
  IResidualValueResult,
  IStolenCarsResult,
  IAdsByVinApiResult,
  ILeasingCheckResult,
} from 'app/models/Api';
import { MobileAppUtils } from 'app/utils/MobileAppUtils';
import { IInfoCardProps } from 'app/components/InfoCard';
import { TError } from 'app/types';

interface IAdsReducer {
  adId?: string;
  adDetails?: {
    ad?: Ad;
    benchmark?: Benchmark;
    avgMonthValues?: AvgMonthValues[];
    list?: ComparedObject[];
    listRank?: number;
    listResultsCount?: number;
    match: any;
    discount: number;
  } | null;
  history?: any[];
  adDetailsIsLoading?: boolean;
  adDetailsError?: TError;
  listType: string;
  filter?: AdFilter | null;
  residualValue?: IResidualValueResult;
  residualValueIsLoading?: boolean;
  residualValueError?: TError;
  residualValueByVin?: IResidualValueResult;
  residualValueByVinIsLoading?: boolean;
  residualValueByVinError?: TError;
  stolenCars: IStolenCarsResult;
  stolenCarsIsLoading?: boolean;
  stolenCarsError?: TError;
  myCars?: any[];
  myCarsIsLoading?: boolean;
  myCarsFirstLoadDone?: boolean;
  vin: string;
  adsLists?: object;
  adsByVinList?: IAdsByVinApiResult;
  vinDecode?: any[] | null;
  vinDecodeIsLoading: boolean;
  vinDecodeError: string | null;
  vinLeasingCheck: ILeasingCheckResult | null;
  vinLeasingCheckIsLoading: boolean;
  vinLeasingCheckError: string | null;
  kmHistory: object | null;
  kmHistoryIsLoading: boolean;
  kmHistoryError: string | null;
  estimateCarIsLoading?: boolean;
  estimateCarError?: TError;
  estimateCar?: any;
  adsByVinListIsLoading?: boolean;
  adsByVinListError?: TError;
  highestEquipmentListIsLoading?: boolean;
  highestEquipmentListError?: TError;
  highestEquipmentList?: any;
  adByVinIsLoading?: boolean;
  adByVinError?: TError;
  adByVin?: IAdsByVinApiResult['meta'];
  vehicleRegisterLoading?: boolean;
  vehicleRegisterFetched?: boolean;
  vehicleRegisterData?: IInfoCardProps[];
  [key: string]: any;
}

const initialState: IAdsReducer = {
  vin: '',
  listType: 'top',
  adsLists: {
    topAdsList: {
      docs: [],
      total: 0,
    },
    lastSoldCars: {
      docs: [],
      total: 0,
    },
    lastAddedCars: {
      docs: [],
      total: 0,
    },
  },
  adsByVinList: {
    docs: [],
    total: 0,
    adsImages: [],
    ad: null,
    meta: null,
  },
  vinDecode: [],
  vinDecodeIsLoading: false,
  vinDecodeError: null,
  vinLeasingCheck: null,
  vinLeasingCheckIsLoading: false,
  vinLeasingCheckError: null,
  kmHistoryIsLoading: false,
  kmHistory: {
    data: {
      chartData: {
        kmHistory: [],
      },
      tableData: [],
      tableDataLength: 0,
    },
  },
  kmHistoryError: null,
  residualValue: {
    '20000km': {
      data: [],
      totalDocumentsUsed: 0,
    },
    idealAd: {} as Ad,
    searchedAd: null,
    lastSoldCars: [],
    lastAddedCars: [],
    listWithSearchedAndIdealAd: [],
    marketPrice: {
      value: 0,
      calculationAccuracy: 0,
    },
  },
  stolenCars: {
    policeDbs: {},
    userDbs: {},
    lastCheckedAt: '',
  },
  stolenCarsIsLoading: false,
  stolenCarsError: '',
  myCarsFirstLoadDone: false,
  vehicleRegisterLoading: false,
  vehicleRegisterFetched: false,
  vehicleRegisterData: [],
};

const getDefaultFilter = (ad: Ad, backendMatch: any) => {
  const { price, equipmentCount = 0, kw = 0 } = ad;
  const [priceFrom, priceTo] = AdFilter.calcRoundedValues(price * 0.75, price * 1.25, 'price');
  const [kwFrom, kwTo] = AdFilter.calcRoundedValues(kw * 0.8, kw * 1.2, 'kw');
  const [equipmentCountFrom] = AdFilter.calcRoundedValues(
    equipmentCount * 0.8,
    equipmentCount * 1.2,
    'equipmentCount'
  );
  return new AdFilter({
    yearFrom: (backendMatch.year && backendMatch.year && backendMatch.year.$gte) || ad.year - 1,
    yearTo: (backendMatch.year && backendMatch.year && backendMatch.year.$lte) || ad.year + 1,
    yearIncluded: true,
    priceIncluded: true,
    priceFrom,
    priceTo,
    kwIncluded: true,
    kwFrom,
    kwTo,
    equipmentCountIncluded: true,
    equipmentCountFrom,
    equipmentCountTo: 0,
  });
};

const getFilterObject = (stateFilter: AdFilter, ad: Ad, backendMatch: any): Partial<AdFilter> => {
  const currentStateFilter = stateFilter;
  const yearDefined =
    !(isUndefined(currentStateFilter.yearTo) && isUndefined(currentStateFilter.yearFrom)) &&
    !(currentStateFilter.yearFrom === 0 && currentStateFilter.yearTo === 0);

  if (yearDefined) {
    return currentStateFilter;
  }

  const newFilter = {
    ...currentStateFilter.toObject(),
    ...{
      yearIncluded: true,
      yearFrom: (backendMatch && backendMatch.year && backendMatch.year.$gte) || ad.year - 1,
      yearTo: (backendMatch && backendMatch.year && backendMatch.year.$lte) || ad.year + 1,
    },
  };

  return newFilter;
};

export default function(state = initialState, action: any): IAdsReducer {
  let errCode;
  switch (action.type) {
    case CONSTANTS_API.LOAD_AD_DETAILS:
      return {
        ...state,
        adDetailsIsLoading: true,
      };
    case CONSTANTS_API.LOAD_AD_DETAILS_FAIL: {
      const { message } = action.error;
      return {
        ...state,
        adDetails: null,
        adDetailsError: message,
        adDetailsIsLoading: false,
      };
    }
    case CONSTANTS_API.RESET_FILTER:
      return {
        ...state,
        filter: null,
      };
    case CONSTANTS_API.UPDATE_FILTER:
      return {
        ...state,
        filter: state.filter
          ? state.filter.with(action.payload.filter)
          : new AdFilter(AdFilter.getFilterFromSearchStringFilter(action.payload.filter)),
      };
    case CONSTANTS_API.LOAD_RESIDUAL_VALUE:
      return {
        ...state,
        residualValueIsLoading: true,
      };
    case CONSTANTS_API.LOAD_RESIDUAL_VALUE_SUCCESS: {
      const { data: residualValueData } = action.payload.data;
      return {
        ...state,
        residualValueIsLoading: false,
        residualValueError: null,
        residualValue: residualValueData,
      };
    }
    case CONSTANTS_API.LOAD_RESIDUAL_VALUE_FAIL:
      return {
        ...state,
        residualValueIsLoading: false,
        residualValue: initialState.residualValue,
        residualValueError: action.error.message,
      };
    case CONSTANTS_API.LOAD_RESIDUAL_VALUE_BY_VIN:
      return {
        ...state,
        residualValueByVinIsLoading: true,
      };
    case CONSTANTS_API.LOAD_RESIDUAL_VALUE_BY_VIN_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        residualValueByVinIsLoading: false,
        residualValueByVinError: null,
        residualValueByVin: data,
      };
    }
    case CONSTANTS_API.LOAD_RESIDUAL_VALUE_BY_VIN_FAIL:
      return {
        ...state,
        residualValueByVinIsLoading: false,
        residualValueByVin: initialState.residualValue,
        residualValueByVinError: action.error.message,
      };
    case CONSTANTS_API.LOAD_AD_DETAILS_SUCCESS: {
      const {
        ad,
        benchmark,
        avgMonthValues,
        list,
        listRank,
        listResultsCount,
        discount,
        match,
      } = action.payload.data.data.ad;
      const backendMatch = match;

      const rangedAd = ad;
      rangedAd.total = benchmark.total;

      return {
        ...state,
        adDetails: {
          ad: rangedAd,
          benchmark,
          avgMonthValues: avgMonthValues?.map(item => {
            const date = moment(item.month, 'YYYYMM');
            item.date = date.toDate();
            item.dateStr = date.format('MM/YY');
            return item;
          }),
          listRank,
          list,
          listResultsCount,
          discount,
          match,
        },
        adId: ad._id && ad._id.toString(),
        adDetailsIsLoading: false,
        adDetailsError: null,
        history: ad.history ? ad.history : null,
        filter: state.filter
          ? new AdFilter(getFilterObject(state.filter, ad, backendMatch))
          : getDefaultFilter(ad, backendMatch),
      };
    }
    case CONSTANTS_APP.SET_LIST_TYPE: {
      const { listType } = action.payload;
      return {
        ...state,
        listType,
      };
    }
    case CONSTANTS_API.RESET_AD_DETAILS:
      return {
        ...state,
        adDetails: null,
      };
    case CONSTANTS_API.UPDATE_AD_ID: {
      return {
        ...state,
        adId: action.payload.adId,
      };
    }
    case CONSTANTS_API.LOAD_ESTIMATE_CAR:
      return {
        ...state,
        estimateCarIsLoading: true,
      };
    case CONSTANTS_API.LOAD_ESTIMATE_CAR_SUCCESS: {
      const { data: estimateCarData } = action.payload.data;
      return {
        ...state,
        estimateCarIsLoading: false,
        estimateCarError: null,
        estimateCar: estimateCarData,
      };
    }
    case CONSTANTS_API.LOAD_ESTIMATE_CAR_FAIL:
      return {
        ...state,
        estimateCarIsLoading: false,
        estimateCar: null,
        estimateCarError: action.error.message,
      };
    case CONSTANTS_API.RESET_ESTIMATE_CAR:
      return {
        ...state,
        estimateCarIsLoading: false,
        estimateCar: null,
        estimateCarError: null,
      };
    case CONSTANTS_API.LOAD_HIGHEST_EQUIPMENT_LIST:
      return {
        ...state,
        highestEquipmentListIsLoading: true,
      };
    case CONSTANTS_API.LOAD_HIGHEST_EQUIPMENT_LIST_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        highestEquipmentListIsLoading: false,
        highestEquipmentListError: null,
        highestEquipmentList: data,
      };
    }
    case CONSTANTS_API.LOAD_HIGHEST_EQUIPMENT_LIST_FAIL:
      return {
        ...state,
        highestEquipmentListIsLoading: false,
        highestEquipmentList: null,
        highestEquipmentListError: action.error.message,
      };
    case CONSTANTS_API.LOAD_ADS_BY_VIN:
      return {
        ...state,
        adsByVinListIsLoading: true,
      };
    case CONSTANTS_API.LOAD_ADS_BY_VIN_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        adsByVinListIsLoading: false,
        adsByVinListError: null,
        adsByVinList: data,
      };
    }
    case CONSTANTS_API.LOAD_ADS_BY_VIN_FAIL:
      return {
        ...state,
        adsByVinListIsLoading: false,
        adsByVinList: initialState.adsByVinList,
        adsByVinListError: action.error.message,
      };
    case CONSTANTS_API.RESET_ADS_BY_VIN:
      return {
        ...state,
        adsByVinListIsLoading: false,
        adsByVinList: initialState.adsByVinList,
        adsByVinListError: null,
      };
    case CONSTANTS_API.LOAD_KM_HISTORY:
      return {
        ...state,
        kmHistoryIsLoading: true,
        kmHistoryNotFoundVin: null,
        kmHistoryNotFoundVinIsLoading: false,
        kmHistoryNotFoundVinError: null,
      };
    case CONSTANTS_API.LOAD_KM_HISTORY_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        kmHistoryIsLoading: false,
        kmHistoryError: null,
        kmHistory: data,
      };
    }
    case CONSTANTS_API.LOAD_KM_HISTORY_FAIL: {
      const code = get(action, 'error.response.data.code', null);
      errCode = get(action, 'error.response.data.message', code);
      return {
        ...state,
        kmHistoryIsLoading: false,
        kmHistory: null,
        kmHistoryError: errCode,
      };
    }
    case CONSTANTS_API.RESET_KM_HISTORY: {
      return {
        ...state,
        kmHistoryIsLoading: false,
        kmHistory: initialState.kmHistory,
        kmHistoryError: null,
      };
    }
    case CONSTANTS_API.LOAD_KM_HISTORY_NOT_FOUND_VIN:
      return {
        ...state,
        kmHistoryNotFoundVinIsLoading: true,
      };
    case CONSTANTS_API.LOAD_KM_HISTORY_NOT_FOUND_VIN_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        kmHistoryNotFoundVin: data,
        kmHistoryNotFoundVinIsLoading: false,
        kmHistoryNotFoundVinError: null,
        // reset kmHistory err message and data
        kmHistoryIsLoading: false,
        kmHistoryError: null,
        kmHistory: null,
      };
    }
    case CONSTANTS_API.LOAD_KM_HISTORY_NOT_FOUND_VIN_FAIL: {
      const code = get(action, 'error.response.data.code', null);
      errCode = get(action, 'error.response.data.message', code);
      return {
        ...state,
        kmHistoryNotFoundVinIsLoading: false,
        kmHistoryNotFoundVin: null,
        kmHistoryNotFoundVinError: errCode,
      };
    }
    case CONSTANTS_API.RESET_KM_HISTORY_NOT_FOUND_VIN: {
      return {
        ...state,
        kmHistoryNotFoundVinIsLoading: false,
        kmHistoryNotFoundVin: initialState.kmHistory,
        kmHistoryNotFoundVinError: null,
      };
    }
    case CONSTANTS_API.LOAD_PDF_EXPORT:
      return {
        ...state,
        pdfExportIsLoading: true,
      };
    case CONSTANTS_API.LOAD_PDF_EXPORT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        pdfExport: data,
        pdfExportIsLoading: false,
        pdfExportError: null,
      };
    }
    case CONSTANTS_API.LOAD_PDF_EXPORT_FAIL: {
      const code = get(action, 'error.response.data.code', null);
      errCode = get(action, 'error.response.data.message', code);
      return {
        ...state,
        pdfExportIsLoading: false,
        pdfExport: null,
        pdfExportError: errCode,
      };
    }
    case CONSTANTS_API.SET_VIN: {
      const { vin } = action.payload;
      return {
        ...state,
        vin,
      };
    }
    case CONSTANTS_API.LOAD_PRICE_HISTORY:
      return {
        ...state,
        priceHistoryIsLoading: true,
      };
    case CONSTANTS_API.LOAD_PRICE_HISTORY_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        priceHistoryIsLoading: false,
        priceHistoryError: null,
        priceHistory: data,
      };
    }
    case CONSTANTS_API.LOAD_PRICE_HISTORY_FAIL: {
      const code = get(action, 'error.response.data.code', null);
      errCode = get(action, 'error.response.data.message', code);
      return {
        ...state,
        priceHistoryIsLoading: false,
        priceHistory: null,
        priceHistoryError: errCode,
      };
    }
    case CONSTANTS_API.LOAD_ADS_LISTS:
      return {
        ...state,
        adsListsIsLoading: true,
      };
    case CONSTANTS_API.LOAD_ADS_LISTS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        adsLists: data,
        adsListsIsLoading: false,
        adsListsError: null,
      };
    }
    case CONSTANTS_API.LOAD_ADS_LISTS_FAIL: {
      const code = get(action, 'error.response.data.code', null);
      errCode = get(action, 'error.response.data.message', code);
      return {
        ...state,
        adsLists: initialState.adsLists,
        adsListsIsLoading: false,
        adsListsError: errCode,
      };
    }
    case CONSTANTS_API.LOAD_VIN_DECODE:
      return {
        ...state,
        vinDecode: null,
        vinDecodeIsLoading: true,
        vinDecodeError: null,
      };
    case CONSTANTS_API.LOAD_VIN_DECODE_SUCCESS: {
      const { data } = action.payload.data;
      MobileAppUtils.loadingCarDone();
      return {
        ...state,
        vinDecode: data,
        vinDecodeIsLoading: false,
        vinDecodeError: null,
      };
    }
    case CONSTANTS_API.LOAD_VIN_DECODE_FAIL: {
      MobileAppUtils.loadingCarFailed(`Vin decode failed: ${action.error.message}`);
      const code = get(action, 'error.response.data.code', null);
      errCode = get(action, 'error.response.data.message', code);
      return {
        ...state,
        vinDecode: null,
        vinDecodeIsLoading: false,
        vinDecodeError: errCode,
      };
    }
    case CONSTANTS_API.LOAD_VIN_LEASING_CHECK:
      return {
        ...state,
        vinLeasingCheck: null,
        vinLeasingCheckIsLoading: true,
        vinLeasingCheckError: null,
      };
    case CONSTANTS_API.LOAD_VIN_LEASING_CHECK_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        vinLeasingCheck: data,
        vinLeasingCheckIsLoading: false,
        vinLeasingCheckError: null,
      };
    }
    case CONSTANTS_API.LOAD_VIN_LEASING_CHECK_FAIL: {
      const code = get(action, 'error.response.data.code', null);
      errCode = get(action, 'error.response.data.message', code);
      return {
        ...state,
        vinLeasingCheck: null,
        vinLeasingCheckIsLoading: false,
        vinLeasingCheckError: errCode,
      };
    }
    case CONSTANTS_API.LOAD_STOLEN_CARS:
      return {
        ...state,
        stolenCarsIsLoading: true,
      };
    case CONSTANTS_API.LOAD_STOLEN_CARS_SUCCESS: {
      const { data: stolenCarsData } = action.payload.data;
      return {
        ...state,
        stolenCarsIsLoading: false,
        stolenCarsError: null,
        stolenCars: stolenCarsData,
      };
    }
    case CONSTANTS_API.LOAD_STOLEN_CARS_FAIL:
      return {
        ...state,
        stolenCarsIsLoading: false,
        stolenCars: initialState.stolenCars,
        stolenCarsError: action.error.message,
      };
    case CONSTANTS_API.RESET_MY_CARS:
      return {
        ...state,
        myCarsIsLoading: false,
        myCarsError: null,
        myCarsCount: 0,
        myCars: [],
      };
    case CONSTANTS_API.LOAD_MY_CARS:
      return {
        ...state,
        myCarsIsLoading: true,
      };
    case CONSTANTS_API.LOAD_MY_CARS_SUCCESS: {
      const { items: myCarsData = [], count: myCarsCount } = action.payload.data;
      return {
        ...state,
        myCarsIsLoading: false,
        myCarsFirstLoadDone: true,
        myCarsError: null,
        myCars: [...(state.myCars ? state.myCars : []), ...myCarsData],
        myCarsCount,
      };
    }
    case CONSTANTS_API.LOAD_MY_CARS_FAIL:
      return {
        ...state,
        myCarsIsLoading: false,
        myCarsFirstLoadDone: true,
        myCars: [],
        myCarsCount: 0,
        myCarsError: action.error.message,
      };
    case CONSTANTS_API.REMOVE_MY_CARS:
      return {
        ...state,
        removeMyCarsIsLoading: true,
      };
    case CONSTANTS_API.REMOVE_MY_CARS_SUCCESS: {
      return {
        ...state,
        removeMyCarsIsLoading: false,
        removeMyCarsError: null,
      };
    }
    case CONSTANTS_API.REMOVE_MY_CARS_FAIL:
      return {
        ...state,
        removeMyCarsIsLoading: false,
        removeMyCarsError: action.error.message,
      };

    case CONSTANTS_API.SET_FAVORITE_MY_CARS:
      return {
        ...state,
        setFavoriteMyCarsIsLoading: true,
      };
    case CONSTANTS_API.SET_FAVORITE_MY_CARS_SUCCESS: {
      const { vin, favorite } = action.payload.data;

      const newMyCars = state.myCars ? [...state.myCars] : [];
      const ind = newMyCars.findIndex(i => i.vin === vin);
      if (ind > -1) {
        newMyCars[ind].favorite = favorite;
      }

      return {
        ...state,
        myCars: newMyCars,
        setFavoriteMyCarsIsLoading: false,
        setFavoriteMyCarsError: null,
      };
    }
    case CONSTANTS_API.SET_FAVORITE_MY_CARS_FAIL:
      return {
        ...state,
        setFavoriteMyCarsIsLoading: false,
        setFavoriteMyCarsError: action.error.message,
      };
    case CONSTANTS_API.LOAD_AD_BY_VIN:
      return {
        ...state,
        adByVinIsLoading: true,
      };
    case CONSTANTS_API.LOAD_AD_BY_VIN_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        adByVinIsLoading: false,
        adByVinError: null,
        adByVin: data,
      };
    }
    case CONSTANTS_API.LOAD_AD_BY_VIN_FAIL:
      return {
        ...state,
        adByVinIsLoading: false,
        adByVin: initialState.adByVin,
        adByVinError: action.error.message,
      };

    case CONSTANTS_API.VEHICLE_REGISTER_LOADING: {
      const { loading } = action.payload;
      return {
        ...state,
        vehicleRegisterLoading: loading,
      };
    }
    case CONSTANTS_API.VEHICLE_REGISTER_FETCHED: {
      const { fetched } = action.payload;
      return {
        ...state,
        vehicleRegisterFetched: fetched,
      };
    }
    case CONSTANTS_API.VEHICLE_REGISTER_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        vehicleRegisterData: data,
      };
    }
    default:
      return state;
  }
}
