import styled, { css } from 'styled-components/macro';
import { mediaQuery } from 'styles/shared.styles';

export const StyledTable = styled('div')({
  position: 'relative',
  height: '100%',
});

export const TableLayout = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'auto',
  [mediaQuery.print]: {
    display: 'block',
    pageBreakAfter: 'auto',
  },
});

export const TableHeader = styled('div')({
  height: '80px',
});

export const TableFooter = styled('div')({
  padding: '16px 20px 24px 20px',
});

export const rowFluidStyle = css({
  height: 'auto',
  padding: '10px 0',
});

export const rowHeaderStyle = css({
  height: '80px',
  backgroundColor: 'transparent',
});

export const rowStripedStyle = css({
  backgroundColor: 'white',
});

export const TableRow = styled('div')(
  {
    alignItems: 'center',
    backgroundColor: '#F0F2F6',
    display: 'flex',
    height: '60px',
    minWidth: '100%',
    pageBreakInside: 'avoid',
    width: 'fit-content',
    [mediaQuery.print]: {
      minHeight: '42px',
      overflow: 'visible',
      padding: 0,
    },
  },
  (props: any) => (props.fluid ? rowFluidStyle : null),
  (props: any) => (props.header ? rowHeaderStyle : null),
  (props: any) => (props.striped ? rowStripedStyle : null)
);

export const TableColumn = styled('div')({
  width: '100px',
  paddingLeft: '10px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ':last-child': {
    paddingRight: '28px',
  },
  [mediaQuery.medium]: {
    paddingLeft: '28px',
  },
});

export const TableOverflow = styled('div')({
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, .18)',
  fontSize: '48px',
});
