import * as React from 'react';
import { Box } from 'reflexbox/styled-components';
import { useQuery } from 'react-query';
import { AxiosResponse, AxiosError } from 'axios';
import { Redirect } from 'react-router';

import { FreeReportNotification } from 'app/components/VerifyVin/components/FreeReportNotification';
import { ButtonPayReport } from 'app/components/VerifyVin/components/ButtonPayReport';
import { LeasingCheck } from 'app/components/VerifyVin/components/LeasingCheck';
import { PaidVinNotification } from 'app/components/VerifyVin/components/PaidVinNotification';
import { PremiumReportBanner } from 'app/components/VerifyVin/components/PremiumReportBanner';
import { SmartReport } from 'app/components/VerifyVin/components/SmartReport';
import { StolenCarsContainer } from 'app/components/VerifyVin/components/StolenCars';
import { VerificationSummaryContainer } from 'app/components/VerifyVin/components/VerificationSummary';
import { VerifyVinAdsHistoryContainer } from 'app/components/VerifyVin/components/VerifyVinAdsHistory';
import { VerifyVinDecodeContainer } from 'app/components/VerifyVin/components/VerifyVinDecode';
import { VerifyVinKmHistoryContainer } from 'app/components/VerifyVin/components/VerifyVinKmHistory';
import { VerifyVinPriceAdvisorContainer } from 'app/components/VerifyVin/components/VerifyVinPriceAdvisor';
import { VerifyVinLeasingCheckContainer } from 'app/components/VerifyVin/components/VerifyVinLeasingCheck';
import { DataLoading } from 'app/components/DataLoading';
import { EnumAdLoaderTexts } from 'app/components/AdLoader';
import { PdfExportFooter } from 'app/components/PdfExport';
import { PrintFlex } from 'app/components/PrintFlex';
import { VehicleRegister } from 'app/components/VerifyVin/components/VehicleRegister';

import { useQueryParams } from 'app/hooks';
import { apiVerifyVinPayment } from 'app/api/payments';
import { MobileAppUtils } from 'app/utils/MobileAppUtils';
import { ResponsiveSection } from 'app/components/ResponsiveSection';
import { SectionWrapperWithPageBreak } from 'app/components/_shared_';
import { useVerifyVinRoute, useAuthenticated } from 'app/hooks';
import {
  onPaymentRetry,
  checkIsPremiumReport,
  checkIsFreeReport,
  redirectToFreeReport,
  redirectToPremiumReport,
} from './VerifyVinPrivate.utils';

interface IVerifyVinPrivateProps {
  vin: string;
  isPdfMode: boolean;
  isValidVin: boolean;
}

export const VerifyVinPrivate: React.SFC<IVerifyVinPrivateProps> = ({
  vin,
  isValidVin,
  isPdfMode,
}) => {
  const { isAuthenticated, accessToken } = useAuthenticated();
  const orderId = useQueryParams('id');
  const { isPremiumRoute, isMobileApp } = useVerifyVinRoute();

  const { data, error, isSuccess, isFetched, isLoading } = useQuery<AxiosResponse, AxiosError>(
    `getPayment-${accessToken}-${vin}`,
    () =>
      isValidVin ? (apiVerifyVinPayment(vin, orderId, accessToken).catch(e => null) as any) : null,
    {
      cacheTime: 5000,
      retryDelay: 1000,
      retry: onPaymentRetry(vin, orderId),
    }
  );

  const isPaidVin = data?.data?.data?.state === 'PAID' && data?.data?.data?.activeTo;
  const isFreeReport = !isPdfMode && checkIsFreeReport(isAuthenticated, isPaidVin, error);

  if (!isFetched || isLoading)
    return orderId ? <DataLoading loaderText={EnumAdLoaderTexts.VERIFYING_PAYMENT} /> : null;

  if (redirectToFreeReport(isPremiumRoute, isSuccess, isPaidVin, isAuthenticated))
    return <Redirect to={`/vin/${vin}`} />;

  if (redirectToPremiumReport(isPremiumRoute, isSuccess, isPaidVin))
    return <Redirect to={`/vin/${vin}/premium`} />;

  return (
    <>
      {/* Pay report Button on mobile app or mobile webview else Pay report banner on desktop */}
      {isFreeReport && !isMobileApp && (
        <>
          <FreeReportNotification />
          <ButtonPayReport />
        </>
      )}

      {!isPdfMode && checkIsPremiumReport(isSuccess, isPaidVin) && (
        <PaidVinNotification activeTo={data?.data?.data?.activeTo} />
      )}

      <PrintFlex flexDirection='column' maxWidth='1200px' margin='0 auto'>
        <ResponsiveSection mt={[0, '30px', '35px']} flexDirection='column'>
          <VerificationSummaryContainer isValidVin={isValidVin} />
        </ResponsiveSection>

        {isValidVin && (
          <ResponsiveSection mx={[MobileAppUtils.isInApp() ? 0 : '10px', 0, 0]}>
            <Box width={[1]}>
              <SectionWrapperWithPageBreak>
                <SmartReport />
              </SectionWrapperWithPageBreak>
            </Box>
          </ResponsiveSection>
        )}

        {isValidVin && (
          <ResponsiveSection>
            <Box width={[1]}>
              <SectionWrapperWithPageBreak>
                <VerifyVinDecodeContainer />
              </SectionWrapperWithPageBreak>
            </Box>
          </ResponsiveSection>
        )}

        {isValidVin && (
          <ResponsiveSection>
            <Box width={[1]}>
              <SectionWrapperWithPageBreak>
                <VerifyVinKmHistoryContainer />
              </SectionWrapperWithPageBreak>
            </Box>
          </ResponsiveSection>
        )}

        <ResponsiveSection>
          <Box width={[1]}>
            <SectionWrapperWithPageBreak>
              <VerifyVinAdsHistoryContainer isValidVin={isValidVin} />
            </SectionWrapperWithPageBreak>
          </Box>
        </ResponsiveSection>

        {isValidVin && (
          <ResponsiveSection>
            <Box width={[1]}>
              <SectionWrapperWithPageBreak>
                <VerifyVinPriceAdvisorContainer vin={vin} />
              </SectionWrapperWithPageBreak>
            </Box>
          </ResponsiveSection>
        )}

        {isValidVin && (
          <ResponsiveSection>
            <Box width={[1]} sx={{ position: 'relative' }}>
              <SectionWrapperWithPageBreak>
                {!isMobileApp && isAuthenticated && !isPremiumRoute && <PremiumReportBanner />}
                <StolenCarsContainer />
              </SectionWrapperWithPageBreak>
            </Box>
          </ResponsiveSection>
        )}

        {isValidVin && (
          <ResponsiveSection>
            <Box width={[1]} sx={{ position: 'relative' }}>
              <SectionWrapperWithPageBreak>
                <LeasingCheckComponent />
              </SectionWrapperWithPageBreak>
            </Box>
          </ResponsiveSection>
        )}

        {isValidVin && (
          <ResponsiveSection>
            <Box width={[1]} sx={{ position: 'relative' }}>
              <SectionWrapperWithPageBreak>
                {!isMobileApp && isAuthenticated && !isPremiumRoute && <PremiumReportBanner />}
                <VehicleRegister />
              </SectionWrapperWithPageBreak>
            </Box>
          </ResponsiveSection>
        )}
      </PrintFlex>

      {isPdfMode && <PdfExportFooter />}
    </>
  );
};

const LeasingCheckComponent = () => {
  const { isPremiumVersion } = useVerifyVinRoute();
  if (isPremiumVersion) return <VerifyVinLeasingCheckContainer />;
  return (
    <>
      <PremiumReportBanner />
      <LeasingCheck />
    </>
  );
};
